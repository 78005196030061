header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    padding-inline: 20px;

    transition: background 0.3s, color 0.3s;

    .primaryNavigation {
        max-width: 1140px;
        margin: auto;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        #menuToggle {
            display: none;
        }

        @media (max-width: 720px) {
            #menuToggle {
                display: block;
                background: transparent;
                color: white;
                border: none;
                outline: none;
                font-size: 2rem;
                margin-inline-end: 15px;
            }

            .hamburgerMenu {
                position: absolute;
                top: -1000px;
                left: 0;
                flex-direction: column;
                /* align-items: flex-start !important; */
                justify-content: flex-start !important;
                padding: 10px;
                width: 100%;
                opacity: 0;
                pointer-events: none;
                transition: top 0.3s, opacity 0.3s;

                .logoContainer {
                    width: 50%;
                    min-width: 200px;
                }

                background: #121212;
                height: calc(100dvh - 80px);

                ul {
                    flex-direction: column;
                    text-align: center !important;
                    /* align-items: flex-start !important; */
                    justify-content: flex-start !important;
                    font-size: 1.4rem;

                    li a:hover {
                        color: #A58D72 !important;
                    }
                }
            }

            .hamburgerMenu.active {
                top: 100%;
                opacity: 1;
                pointer-events: auto;
            }
        }

        .hamburgerMenu {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;

            .logoContainer {
                width: 60px;
                aspect-ratio: 1;
                background: transparent;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }

            ul {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 15px;
                list-style: none;

                a {
                    color: inherit;
                    text-decoration: none;
                }
            }
        }

        
        .searchBar {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 350px;
            background: #f1f1f170;
            backdrop-filter: blur(15px);
            border-radius: 50px;
            overflow: hidden;
            margin-inline-start: auto;
            margin-inline-end: 15px;

            i {
                height: 100%;
                aspect-ratio: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
            }

            input {
                flex: 1 0;
                height: 100%;
                background: none;
                outline: none;
                border: none;
                font-family: "Poppins", system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                color: white;
                
            }

            input::placeholder {
                color: white;
                opacity: 0.6;
            }

            transition: all 0.3s;
        }
        
        .searchBar:has(input:focus) {
            background: white;
            color: black;
            
            input {
                color: black;
            }

            input::placeholder {
                color: black;
            }
        }
        
        @media (max-width : 980px) {
            .searchBar {
                width: 40px;
                background: transparent;

                input {
                    display: none;
                }
            }
        }

        .account {
            display: flex;
            align-items: center;
            justify-content: center;
            
            a {
                color: inherit;
                text-decoration: none;
                opacity: 0.7;
                transition: opacity 0.3s;
            }

            a:hover {
                opacity: 1;
            }
        }
    }
}

header.scrolled {
    background: #00000070;
    backdrop-filter: blur(15px);
}

@media screen and (max-width: 768px) {
    
}