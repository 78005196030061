.swiper {
    width: 100%;
    height: 100%;
}

.swiperContainer {
    max-width: 1100px;
    margin: auto;
    padding-inline: 20px;
}

.swiper-slide {
    .container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;

        >* {
            flex: 1 1 370px;
            max-width: 550px;
        }

        .imageContainer {
            width: 100%;
            aspect-ratio: 1;

            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .left {
            display: grid;
            gap: 5px;
            padding-block-start: 30px;

            h2 {
                font-size: 50px;
                font-weight: 300;
            }

            @media (max-width : 480px) {
                h2 {
                    font-size: 35px;
                }
            }

            .timeAndDate {
                color: #A58D72;
            }

            p {
                font-weight: 300;
            }

            p.summary {
                line-height: 1.6;
                font-size: 0.95em;
                font-weight: 300;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                max-height: 100px;
                -webkit-text-size-adjust: none;
                display: -webkit-box;
            }

            .buttons {
                padding-block: 20px;

                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 15px;

                >button {
                    width: 150px;
                    height: 50px;
                    border-radius: 5px;
                    border: none;
                    outline: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;
                    font-family: "Poppins", system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                    cursor: pointer;
                }

                @media (max-width : 420px) {
                    > button {
                        width: 50px;
                        border-radius: 50%;
                    }
                    
                    > button i {
                        font-size: 1.3rem;
                    }

                    > button span {
                        display: none;
                    }

                    > button:hover span {
                        display: block !important;
                    }
                }

                .watchNowButton {
                    background: #D8790E;
                }
            }
        }
    }
}

.swiper-button-prev,
.swiper-button-next {
    position: absolute;
    top: auto !important;
    bottom: 50px !important;
    width: calc(var(--swiper-navigation-size) / 44 * 27);
    height: var(--swiper-navigation-size);
    left: auto !important;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white !important;
    /* border: 1px solid white; */
    background: #D8790E;
    height: 50px !important;
    width: 50px !important;
    border-radius: 50%;
    aspect-ratio: 1 !important;
    padding: 5px;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
    background: #ffffff;
    transition: all 0.3s;
}

.swiper-button-prev {
    right: 72px !important;
}

.swiper-button-prev:hover::after,
.swiper-button-next:hover::after {
    color: black;
}

.swiper-button-prev::after,
.swiper-button-next::after {
    font-size: 14px !important;
    font-weight: 600;
}