.heroSection {
  padding-block-start: 80px;
  min-height: 600px;
  background: linear-gradient(
    to bottom,
    transparent,
    #4d2e0a 33.3%,
    transparent
  );
}

.featured {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;

  /* width: min(1100px, 100%); */
  margin: auto;
  
  .embla {
      width: 100%;

    .embla__container {
      display: flex;
      gap: 10px;
      margin-left: 0 !important;

      .embla__slide {
        background: white;
        color: black;
        aspect-ratio: 16/9 !important;
        flex: 1 0 min(500px, 100%);

        display: flex;
        align-items: center;
        justify-content: center;

        overflow: hidden;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
      }
    }
  }
}

.moviePreview {
  display: flex;
  align-items: center;
  max-width: 1100px;
  margin-block: 20px;
  aspect-ratio: 16/9;
  margin-inline: auto;
  overflow: hidden;
  padding: 20px;

  .container {
    width: 100%;
    height: 100%;
    overflow: hidden;

    > div {
      width: 100% !important;
      height: 100% !important;
    }

    video {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }
  }
}

section.videos {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
  padding-inline: 20px;

  h3 {
    font-size: 35px;
    font-weight: 400;
    margin-block-end: 10px;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;

    .imageContainer {
      flex: 1 0 450px;
      width: min(400px, 100%);
      background: white;
      aspect-ratio: 16/9;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}
